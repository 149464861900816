import { SignUp } from '@clerk/remix'

import { Card, CardContent } from '~/components/ui/card'

export const ClerkLayout = {
	helpPageUrl: 'mailto:support@doreveal.com',
	termsPageUrl: 'https://doreveal.com/terms-of-service',
	privacyPageUrl: 'https://doreveal.com/privacy-policy',
	logoImageUrl: '/static-assets/square-logo.svg',
	logoLinkUrl: '/',
}

export default function SignUpPage() {
	return (
		<>
			<div className="p-4 bg-indigo-950 top-0 left-0 w-full mb-7">
				<h2 className="text-3xl font-medium text-center text-indigo-200">
					Try Reveal for Free
				</h2>
			</div>
			<div className="container mx-auto px-4 py-8">
				<div className="flex flex-col lg:flex-row justify-center items-start space-y-4 lg:space-y-0 lg:space-x-8">
					<div className="w-full lg:w-1/2 max-w-md">
						<SignUp
							appearance={{
								layout: ClerkLayout,
							}}
						/>
					</div>
					<div className="w-full lg:w-1/2 max-w-md">
						<WhyConsiderReveal />
					</div>
				</div>
			</div>
		</>
	)
}

const WhyConsiderReveal = () => {
	const features = [
		'Transcribes and translates.',
		'Reviews transcripts to find answers to research questions.',
		'Suggests additional themes.',
		'Tests hypotheses.',
		'Answers any additional questions that come up during the analysis.',
		'Compares all interviews to find similarities and differences.',
	]

	return (
		<Card className="p-6 border-0 drop-shadow-none">
			<CardContent>
				<h2 className="text-6xl mb-4 text-slate-700">Why consider Reveal?</h2>
				<ul className="space-y-2 mb-4 p-3">
					{features.map((feature, index) => (
						<li key={index} className="list-disc">
							{feature}
						</li>
					))}
				</ul>
				<p className="text-md">
					Your trial allows analysis of{' '}
					<strong className="font-medium">up to 3 user interviews</strong>.
				</p>
			</CardContent>
		</Card>
	)
}
